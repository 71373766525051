import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import { LoginResponse } from '../types/account'
import secureLocalStorage from "react-secure-storage";
//import { fetchCount } from './counterAPI';

export interface AccountState {
    authKey: string | null;
    authExpDate: Date | null;
    accountName: string | null;
    companyName: string | null;
    url: string| null;
    test: string | null;
    //status: 'idle' | 'loading' | 'failed';
}

const initialState: AccountState = {
    authKey: null,
    authExpDate: null,
    accountName: null,
    companyName: null,
    url: null,
    test: "AAA"
    //status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
//export const incrementAsync = createAsyncThunk(
//    'counter/fetchCount',
//    async (amount: number) => {
//        //const response = await fetchCount(amount);
//        const response = {data: 1};
//        // The value we return becomes the `fulfilled` action payload
//        return response.data;
//    }
//);

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        addLoginInformation: (state, action: PayloadAction<LoginResponse|null>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            if (action.payload != null) {
                state.authKey = action.payload.token;
                state.authExpDate = action.payload.expiration;
                state.accountName = action.payload.accountName;
                state.companyName = action.payload.companyName;
                state.url = action.payload.url;
                secureLocalStorage.setItem("token", JSON.stringify(action.payload));
            }
        },
        changeTest: (state, action: PayloadAction<string>) => {
            state.test = action.payload;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    //extraReducers: (builder) => {
    //    builder
    //        .addCase(incrementAsync.pending, (state) => {
    //            state.status = 'loading';
    //        })
    //        .addCase(incrementAsync.fulfilled, (state, action) => {
    //            state.status = 'idle';
    //            state.value += action.payload;
    //        });
    //},
});

//export const localstorageSlice = createSlice({
//    name: 'localstorage',
//    initialState,
//    // The `reducers` field lets us define reducers and generate associated actions
//    reducers: {
//        getCustomersSuccess(state, action) {
//            state.authKey = action.payload.token;
//            state.authExpDate = action.payload.expiration;
//            state.accountName = action.payload.accountName;
//            state.companyName = action.payload.companyName;
//            state.url = action.payload.url;
//        },
//    }
//});

//export function getstorage(token:) {
//    return async () => {
//        dispatch(slice.actions.getCustomersSuccess(token));
//    }
//}

export const { addLoginInformation } = accountSlice.actions;

export const authKey = (state: RootState) => state.account.authKey;
export const url = (state: RootState) => state.account.url;
export const company = (state: RootState) => state.account.companyName;
export const name = (state: RootState) => state.account.accountName;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const authKey = (state: RootState) => state.account.authKey;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
//export const incrementIfOdd = (amount: number): AppThunk => (
//    dispatch,
//    getState
//) => {
//    const currentValue = getKey(getState());
//    if (currentValue % 2 === 1) {
//        dispatch(incrementByAmount(amount));
//    }
//};

export default accountSlice.reducer;
