import React, { useState, useReducer } from 'react';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { addLoginInformation } from '../store/accountSlicer';
import { apiAccount } from '../api/account';

//import "./LayoutFull.css";

//type LayoutFullProps = {
//    children: React.ReactNode; //type children
//};

export const FormLogin = () => {
    const dispatch = useAppDispatch();

    const [inputValues, setInputValues] = useReducer(
        (state: any, newState: any) => ({ ...state, ...newState }),
        { email: '', password: '', showError: false }
    );

    let fieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setInputValues({ [name]: value });
    };

    let sendLogin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setInputValues({ showError: false })
        apiAccount.login(inputValues.email, inputValues.password)
            .then(data => { dispatch(addLoginInformation(data)); })
            .catch(ex => { setInputValues({ showError: true }); });
    };

    const _appIsLoading = useAppSelector(state => state.general.appIsLoading)

    if (_appIsLoading) return (<div />);
    else {
        return (
            <div id="ks-form-login">
                <div id="ks-form-login-inner" className="ks-form-container">
                    <h1>Login</h1>
                    <div className="ks-form-fieldgroup">
                        <label htmlFor="email">E-Mail</label>
                        <input id="email" name="email" type="text" value={inputValues.email} placeholder="red.pill@rabbithole.com" autoFocus onChange={(e) => fieldChange(e) } />
                    </div>
                    <div className="ks-form-fieldgroup">
                        <label htmlFor="password">Password</label>
                        <input id="password" name="password" type="password" value={inputValues.password} onChange={(e) => fieldChange(e)} />
                    </div>
                    <div className="ks-form-fieldgroup">
                        <label />
                        <button onClick={ (e) => sendLogin(e) }>Send</button>
                    </div>
                    {(inputValues.showError) ? <span className="ks-form-error">Email and/or password are incorrect.</span> : <React.Fragment />}
                </div>
            </div>
        );
    }
};
