import { sendLogout } from '../logout';

let handleErrorResponse = (response: Response) => {
    if (response.ok) return response;
    else if (response.status == 403)
        sendLogout();
    else //To DO: We can call another page for 404 or 500 status
        return response;
};

export { handleErrorResponse }