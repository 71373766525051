import * as KS from "../types/survey";
import { apiHost } from '../store/generalSlicer';
import { authKey } from '../store/accountSlicer';
import { store } from '../store/store';
import { handleErrorResponse } from '../api/interception';

export const apiSurvey = {
    //getSurvey: (surveyId: string): Promise<KS.LoginRequest> => {
    //    return fetch(`v1/Account/Login?surveyId=` + surveyId) //v1/Insight/GetPage?respId=6126c0f26c39ca115d73cd9a
    //        .then(response => response.json() as Promise<KS.LoginRequest>);
    //},

    getSurveys: (): Promise<KS.ISurveyResponse[]> => {
        const _apiHost = apiHost(store.getState());
        const _authKey = authKey(store.getState());
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + _authKey },
        };

        return fetch(_apiHost + `/v1/Survey`, requestOptions)
            .then(response => handleErrorResponse(response)?.json() as Promise<KS.ISurveyResponse[]>);
    },

    //https://stackoverflow.com/questions/35206589/how-to-download-fetch-response-in-react-as-file
    getExport: (surveyId: string, panelId: string): Promise<string> => {
        const _apiHost = apiHost(store.getState());
        const _authKey = authKey(store.getState());
        const requestOptions = {
            method: 'POST',
            headers: { 'Authorization': 'Bearer ' + _authKey, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                surveyId: surveyId,
                panelId: panelId
            })
        };

        return fetch(_apiHost + `/v1/Survey/Export`, requestOptions)
            .then(response => handleErrorResponse(response)?.text() as Promise<string>);
    },

    //getResponseByHash: (hash: string, surveyId: string): Promise<KS.PageResultDto> => {
    //    // Only load data if it's something we don't already have (and are not already loading)
    //    return fetch(`v1/Insight/GetPageByHash?surveyId=` + surveyId + `&hash=` + hash)
    //        .then(response => response.json() as Promise<KS.PageResultDto>);
    //},

    //getResponseById: (resId: string): Promise<KS.ResponseResultDto> => {
    //    // Only load data if it's something we don't already have (and are not already loading)
    //    return fetch(`v1/Insight/GetResponse?respId=` + resId)
    //        .then(response => response.json() as Promise<KS.ResponseResultDto>);
    //},

    //getNextPage: (responseId: string, values: KS.ValueDto[]): Promise<KS.NextResultDto> => {
    //    const requestOptions = {
    //        method: 'POST',
    //        headers: { 'Content-Type': 'application/json' },
    //        body: JSON.stringify({
    //            responseId: responseId,
    //            values: values
    //        })
    //    };
    //    return fetch(`v1/Insight/Next`, requestOptions)
    //        .then(response => response.json() as Promise<KS.NextResultDto>)
    //},

    //requestBack: (responseId: string): Promise<KS.PreviousResultDto> => {
    //    const requestOptions = {
    //        method: 'POST',
    //        headers: { 'Content-Type': 'application/json' },
    //        body: JSON.stringify({
    //            responseId: responseId
    //        })
    //    };
    //    return fetch(`v1/Insight/Previous`, requestOptions)
    //        .then(response => response.json() as Promise<KS.PreviousResultDto>);
    //}
};