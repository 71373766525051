import React, { Component } from 'react';
////import { connect } from 'react-redux';
////import type { RootState, AppDispatch } from '../../store/store';

import "./Layout.css";

type LayoutFullProps = {
    children: React.ReactNode; //type children
};

export const LayoutFull = (props: LayoutFullProps) => {

    return (
        <div id="ks-layout-full-root">
            <main>
                {props.children}
            </main>
            <footer>
                
                <div className="copyright">&copy;KeenSight, 2022</div>
            </footer>
        </div>
    );
};
