import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import type { RootState, AppDispatch } from './store/store';
import { useAppSelector, useAppDispatch } from './store/hooks';
import { addLoginInformation } from './store/accountSlicer';
import { addSettings, setAppIsLoading } from './store/generalSlicer';
import {Routes, Route} from "react-router-dom";
//import { Layout } from './components/Layout';
import { Layout } from './components/framework/Layout';
import { LayoutFull } from './components/framework/LayoutFull';
import { FormLogin } from './components/FormLogin';
import Survey from './components/survey/Survey';

import { ISurveyResponse } from './types/survey';

import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import { Login } from './components/Login';
import { apiGeneral } from './api/general';
import secureLocalStorage from "react-secure-storage";

// https://daveceddia.com/redux-tutorial/
// https://redux.js.org/usage/usage-with-typescript

import './custom.css'; // TO DELETE??
import './common.min.css';

export const App = () => {
    //static displayName = App.name;
    const dispatch = useAppDispatch();
    let _authKey = useAppSelector(state => state.account.authKey);

    var token = secureLocalStorage.getItem("token");
    var setting = secureLocalStorage.getItem("setting");
    
    if (token != null && _authKey == null) {
        dispatch(addLoginInformation(JSON.parse(token.toString())));
        if (setting != null) {
            dispatch(addSettings(JSON.parse(setting.toString())));
        }
    }
    
    useEffect(() => {
        apiGeneral.getSettings().then(data => {
            dispatch(addSettings(data));
            dispatch(setAppIsLoading(false));
        });
    }, []);
    
    if (_authKey == null || _authKey == "") {
        return (
            <LayoutFull><FormLogin /></LayoutFull>
        );
    }
    else {
        return (
            <Layout>
                <Routes>
                    <Route path='/' element={<Survey />} />

                    <Route path='/home' element={<Home />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/counter' element={<Counter />} />
                    <Route path='/fetch-data' element={<FetchData />} />
                </Routes>
            </Layout>
        );
    }
}
