import React from 'react';
import * as KS from "../../types/panel";
import { apiPanel } from '../../api/panel';
import { connect } from 'react-redux';
import { RootState } from '../../store/store';
import { accountSlice } from '../../store/accountSlicer';
import { generalSlice } from '../../store/generalSlicer';

interface IPanelProps {

}
interface IState {
    panels: KS.IPanelResponse[],
}

type panelMergedProps =
    RootState &
    typeof accountSlice.actions
    & typeof generalSlice.actions
    & IPanelProps;

class Panel extends React.PureComponent<panelMergedProps, IState> {
    constructor(props: panelMergedProps) {
        super(props);
        this.state = {
            panels: []
        };
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        apiPanel.getPanels().then(data => {
            this.setState({ panels: data });
        });
    }

    public render() {

        return (
            <section>
                <h1>Panels</h1>
                <div className="ks-table">
                    <div className="ks-head">
                        <div><b>Name</b></div>
                        <div><b>No of panel members</b></div>
                    </div>
                    {this.state.panels.map((p: KS.IPanelResponse) =>
                        <div key={p.panelId}>
                            <div>{p.panelName}</div>
                            <div>{p.panelMembersCount}</div>
                        </div>
                    )}
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state: RootState) => state;

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Panel as any);
