import React from 'react';
import * as KS from "../../types/survey";
import { apiSurvey } from '../../api/survey';
import { connect } from 'react-redux';
import { RootState } from '../../store/store';
import { accountSlice, AccountState } from '../../store/accountSlicer';
import { generalSlice, GeneralState } from '../../store/generalSlicer';
import FileSaver from 'file-saver';
import FormatDate from '../../util/dateformat';
import Panel from '../../components/panel/Panel';

interface ISurveyProps {
    //item: IG.ItemDiscrete,
    //answers: IG.ValueDto[]
}
interface IState {
    surveys: KS.ISurveyResponse[],
}

type surveyMergedProps =
    //AccountState &
    //GeneralState &
    RootState &
    typeof accountSlice.actions
    & typeof generalSlice.actions
    & ISurveyProps;

class Survey extends React.PureComponent<surveyMergedProps, IState> {
    constructor(props: surveyMergedProps) {
        super(props);
        this.state = {
            surveys: []
        };
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        apiSurvey.getSurveys().then(data => {
            this.setState({ surveys: data });
        });
    }

    private onExport(e: React.MouseEvent, surveyId: string, panelId: string) {
        apiSurvey.getExport(surveyId, panelId).then(data => {
            var blob = new Blob([data], { type: "text/plain;charset=utf-8" });
            FileSaver.saveAs(blob, "survey-" + FormatDate(new Date()) + ".csv");
        });
    }

    public render() {
        //console.log(this.props);
        return (
            <section>
                <h1>Surveys</h1>
                <div className="ks-table">
                    <div className="ks-head">
                        <div><b>Name</b></div>
                        <div><b>URL</b></div>
                        <div><b>Action</b></div>
                    </div>
                    {this.state.surveys.map((s: KS.ISurveyResponse) =>
                        <div key={s.id}>
                            <div>{s.name}</div>
                            <div><a target="_blank" href={"https://" + this.props.general.surveyDomain + "/s/" + s.id}>
                                https://{this.props.general.surveyDomain}/s/{s.id}
                            </a></div>
                            <div>
                                <span className="ks-link" onClick={(e) => this.onExport(e, s.id, s.panelId)}>Export</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className="ks-padding-ten"></div>
                <Panel />
            </section>
        );
    }
}

const mapStateToProps = (state: RootState) => state;

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Survey as any);

//export default connect(
//    (state: RootState) => state.account, // Selects which state properties are merged into the component's props
//    accountSlice.actions && generalSlice.actions // Selects which action creators are merged into the component's props
//)(Survey as any);

//export const SurveyAlt = () => {
//    const dispatch = useAppDispatch();
//    const [surveys, setSurveys] = useState([] as KS.SurveyResponse[]);
//    const [myMap, setMyMap] = useState(new Map());
//    const updateMap = (k: any, v: any) => {
//        setMyMap(new Map(myMap.set(k, v)));
//    }
//    let listItems: JSX.Element[] = [];
//    useEffect(() => {
//        apiSurvey.getSurveys().then(data => {
//            //dispatch(addApiHost(data.apiHost));
//            //dispatch(setAppIsLoading(false));
//            setSurveys(data);
//            //updateMap()
//            listItems = data.map((survey) =>
//                <li>{survey.name}</li>
//            );
//            console.log(listItems);
//            console.log(data);
//        });
//    }, []);

//    return (
//        <section>
//            <h1>Surveys</h1>
//            <ul>
//                {[...myMap.keys()].map(k => (
//                    <li key={k}>myMap.get(k)</li>
//                ))}
//            </ul>
//            <ul>
//                {listItems}
//            </ul>
//        </section>
//    );
//};