import * as KS from "../types/panel";
import { apiHost } from '../store/generalSlicer';
import { authKey } from '../store/accountSlicer';
import { store } from '../store/store';
import { handleErrorResponse } from '../api/interception';

export const apiPanel = {
    getPanels: (): Promise<KS.IPanelResponse[]> => {
        const _apiHost = apiHost(store.getState());
        const _authKey = authKey(store.getState());
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + _authKey },
        };

        return fetch(_apiHost + `/v1/Panel`, requestOptions)
            .then(response => handleErrorResponse(response)?.json() as Promise<KS.IPanelResponse[]>);
    }
}