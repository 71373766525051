import React, { Component } from 'react';
import { sendLogout } from '../../logout';
////import { connect } from 'react-redux';
////import type { RootState, AppDispatch } from '../../store/store';

import "./Layout.css";

type LayoutProps = {
    children: React.ReactNode; //type children
};

export const Layout = (props: LayoutProps) => {
    
    return (
        <div id="ks-layout-root">
            <header>
                <a onClick={() => sendLogout()} className="ks-logout">Logout</a>
            </header>
            <main>
                {props.children}
            </main>
            <footer>

                <div className="copyright">&copy;KeenSight, 2022</div>
            </footer>
        </div>
    );
};